import $ from 'jquery';  
window.jQuery = $; window.$ = $

import "slick-carousel/slick/slick";

$(function() {
  
  var content_divs = $(".tile img");

  $(document).scroll(function() {
    $('header .m').trigger('adjust:color', {divs: content_divs});
  });
  $('header .m').trigger('adjust:color', {divs: content_divs});
  
  window.addEventListener('popstate', function(e){
    if (e.state) {
      // e.state.href;  
    }      
  }); 
  
  
  $('.slick').slick({
    infinite: false,
    slidesToShow: 1.28,
    centerMode: false,
    dots: true,
    centerPadding: '0',  
    arrows: true,
    responsive: [
     {
       breakpoint: 599,
       settings: {
         slidesToShow: 1,
         arrows: true
       }
     }
   ]
  })

  // Video poster
const reducedMotionMediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')
const videos = document.querySelectorAll('video')

videos.forEach((video) => {
  const btn = video.nextElementSibling

  video.addEventListener('play', () => {
    video.classList.remove('paused')
  })

  video.addEventListener('pause', () => {
    video.classList.add('paused')
  })

  btn.addEventListener('click', (e) => {
    if (video.paused) {
      video.play()
    } else {
      video.pause()
    }
  })

  if (reducedMotionMediaQuery.matches) {
    video.pause()
    video.classList.add('paused')
  }
})
// Video poster
  
});

const imageObserver = new IntersectionObserver((entries, imgObserver) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const lazyImage = entry.target
      lazyImage.src = lazyImage.dataset.src
      lazyImage.classList.remove("lzy_img");
      imgObserver.unobserve(lazyImage);
    }
  })
});


// Lazy load project images
document.addEventListener("DOMContentLoaded", function() {
  const arr = document.querySelectorAll('#projects .lzy_img, #stories .lzy_img')
  arr.forEach((v) => {
    imageObserver.observe(v);
  })
})

$(document).on('click', "a[data-remote=true]", function(e){
  var $this = $(this),
      href = $this.attr('href'),
      target = $this.data('target');
      
  $.ajax({
    type: (($this.data('method') === undefined) ? 'GET' : $this.data('method')),
    url: href,
    beforeSend: function(xhr) {
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
    },
    success: function(html) {
      if (target !== undefined) {
        $(target).replaceWith($(html).find(target));
        
        const arr = document.querySelectorAll('#projects .lzy_img, #stories .lzy_img')
        arr.forEach((v) => {
          imageObserver.observe(v);
        })        
        
      }
    }  
  });
  
  window.history.pushState({href: href}, '', href);
  
  e.preventDefault();
});

$(document).on('submit', "form[data-remote=true]", function(e){
  var $this = $(this),
      href = $this.attr('action'),
      target = $this.data('target');
    
  $.ajax({
    type: (($this.data('method') === undefined) ? 'GET' : $this.data('method')),
    url: href,
    data: $this.serialize(),
    success: function(html) {
      if (target !== undefined) {
        $(target).replaceWith($(html).find(target));
        
        const arr = document.querySelectorAll('#projects .lzy_img, #stories .lzy_img')
        arr.forEach((v) => {
          imageObserver.observe(v);
        }) 
      }
    }  
  });

  e.preventDefault();  
});


$(document).on('adjust:color', "header .m", function(e, divs) {    
  var menu = $(this),
     above = null;
  
  divs['divs'].each(function() {
    if (is_colliding(menu, $(this))) {
      above = $(this)
      return;
    }
  });
  
  if (above != null && $('.cn', above.parents('.tile:first')).length > 0) {
    $("header").removeClass("light dark").addClass('light');
  } else {
    $("header").removeClass("light dark");
  }

});




$(document).on('change', "header input[type=checkbox]", function(e){  
  $('header').toggleClass('open-m')
  e.preventDefault();
});

var is_colliding = function( $div1, $div2 ) {
	// Div 1 data
	var d1_offset             = $div1.offset();
	var d1_height             = $div1.outerHeight( true );
	var d1_width              = $div1.outerWidth( true );
	var d1_distance_from_top  = d1_offset.top + d1_height;
	var d1_distance_from_left = d1_offset.left + d1_width;

	// Div 2 data
	var d2_offset             = $div2.offset();
	var d2_height             = $div2.outerHeight( true );
	var d2_width              = $div2.outerWidth( true );
	var d2_distance_from_top  = d2_offset.top + d2_height;
	var d2_distance_from_left = d2_offset.left + d2_width;

	var not_colliding = ( d1_distance_from_top < d2_offset.top || d1_offset.top > d2_distance_from_top || d1_distance_from_left < d2_offset.left || d1_offset.left > d2_distance_from_left );

	// Return whether it IS colliding
	return ! not_colliding;
};